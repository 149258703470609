<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="tabChanged($event)">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/tab1">
          <ion-icon :icon="baseballOutline" />
          <ion-label>Content</ion-label>
        </ion-tab-button>

        <!-- <ion-tab-button tab="tab2" href="/tabs/tab2">
          <ion-icon :icon="informationCircleOutline" />
          <ion-label>About</ion-label>
        </ion-tab-button> -->

        <ion-tab-button tab="tab3" href="/tabs/tab3">
          <ion-icon :icon="listOutline" />
          <ion-label>Box Scores</ion-label>
        </ion-tab-button>

        <ion-tab-button
          :tab="page.id"
          :href="'/custom/' + page.id"
          v-for="page of pages"
          :key="page.id"
        >
          <ion-icon :icon="icons[page.icon]" />
          <ion-label>{{ page.title }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonTitle,
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  baseballOutline,
  informationCircleOutline,
  listOutline,
  trophyOutline,
  accessibilityOutline,
  atOutline,
  barChartOutline,
  bookmarksOutline,
  checkmarkDoneCircleOutline,
  fileTrayFullOutline,
  locateOutline,
  personCircleOutline,
  timeOutline,
  trendingUpOutline,
} from "ionicons/icons";

export default {
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonTitle,
  },
  data() {
    return {
      baseballOutline,
      listOutline,
      icons: {
        ellipse,
        square,
        triangle,
        baseballOutline,
        informationCircleOutline,
        trophyOutline,
        listOutline,
        accessibilityOutline,
        atOutline,
        barChartOutline,
        bookmarksOutline,
        checkmarkDoneCircleOutline,
        fileTrayFullOutline,
        locateOutline,
        personCircleOutline,
        timeOutline,
        trendingUpOutline,
      },
      pages: [],
      activeTab: null,
    };
  },
  ionViewWillEnter: function () {
    this.$axios.get("/app/pages/scann").then((results) => {
      this.pages = results.data;
    });
  },
  methods: {
    tabChanged: function ($event) {
      console.log("event", $event);

      this.$router.push({ path: $event.tab });
    },
  },
};
</script>

<style scoped>
ion-tab-bar {
  --background: #070c19;
  border-top: 3px solid #ffb32a;
}

ion-tab-button {
  --color: #fff;
  --color-selected: #ffb32a;
}
</style>
