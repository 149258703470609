<template>
  <ion-app>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="success" v-if="isTheStreamLive">Live</ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img src="/assets/images/logo.png" height="40" alt="">
        </ion-title>
        <ion-buttons slot="end">
          <ion-button color="success" v-if="isTheStreamLive">Live</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonButtons, IonTitle, IonButton, IonHeader, IonToolbar } from '@ionic/vue';
// import axios from 'axios'
export default {
  name: 'App',
  data() {
    return {
      isTheStreamLive: false,
    }
  },
  components: {
    IonApp,
    IonRouterOutlet,
    IonButtons,
    IonTitle,
    IonButton, IonHeader, IonToolbar
  },

  methods: {
    
  }
};
</script>

<style scoped>
ion-toolbar {
  --background: #070c19;
  border-bottom: 3px solid #ffb32a;
}


</style>